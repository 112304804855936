import { useEditContext } from 'react-admin';
import { useQuery } from '@keyops-hcp/ui-components';
import { AdBoardDto, AdBoardEngagementParticipantsDto } from '@keyops-hcp/dtos';

import { getParticipantsByAdBoardId } from '../../../../api/adboards-api';

export type AdBoardEngagementDataDto = {
  isLoading: boolean;
  isError: boolean;
  adBoardData: AdBoardDto;
  totalSections: number;
  participantsData: AdBoardEngagementParticipantsDto[];
  activeToday: number;
  totalParticipants: number;
  inProgressParticipants: number;
  completedParticipants: number;
  completionPercentage: number;
};

const useAdBoardEngagementData = (): AdBoardEngagementDataDto => {
  const { record: adBoardData } = useEditContext();
  const adBoardId = adBoardData?.id;

  const {
    isLoading,
    isError,
    data: participantsData = [],
  } = useQuery({
    queryKey: ['getParticipantsByAdBoardId', adBoardId],
    queryFn: () =>
      adBoardId ? getParticipantsByAdBoardId(adBoardId) : Promise.resolve([]),
    enabled: !!adBoardId,
  });

  const today = new Date().setHours(0, 0, 0, 0);

  const totalSections = adBoardData?.sections?.length || 0;

  const { activeToday, inProgressParticipants, completedParticipants } =
    participantsData.reduce(
      (acc, participant) => {
        const { updatedAt, progress } = participant;
        const sectionProgress =
          progress?.progressDetails?.sectionProgress || [];
        const finishedSections = sectionProgress.filter(
          (section) => section.finished
        ).length;

        // Check if participant was active today
        if (updatedAt && new Date(updatedAt).setHours(0, 0, 0, 0) === today) {
          acc.activeToday++;
        }

        // Count participants based on their progress
        if (finishedSections < totalSections) {
          acc.inProgressParticipants++;
        } else if (finishedSections === totalSections) {
          acc.completedParticipants++;
        }

        return acc;
      },
      { activeToday: 0, inProgressParticipants: 0, completedParticipants: 0 }
    );

  const totalParticipants = participantsData.length;
  const completionPercentage = totalParticipants
    ? Math.round((100 * completedParticipants) / totalParticipants)
    : 0; // Avoid division by zero

  return {
    isLoading,
    isError,
    adBoardData,
    totalSections,
    participantsData,
    activeToday,
    totalParticipants,
    inProgressParticipants,
    completedParticipants,
    completionPercentage,
  };
};

export default useAdBoardEngagementData;
