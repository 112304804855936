import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Loading, useList } from 'react-admin';
import { Box, Pagination, SelectChangeEvent } from '@mui/material';

import FeedFilters from './FeedFilters';
import FeedList from './FeedList';
import useProgressFeed from '../../../../custom-hooks/useProgressFeed';

const ITEMS_PER_PAGE = 10;

const ProgressFeed = () => {
  const [filters, setFilters] = useState({
    searchQuery: '',
    sectionSearch: '',
    activitySearch: '',
  });

  const handleSearchChange = useCallback(
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>,
      field: string
    ) => {
      setFilters((prev) => ({ ...prev, [field]: e.target.value }));
    },
    []
  );

  const { isLoading, isError, data } = useProgressFeed();
  const {
    data: listData,
    total: totalNumberOfData,
    page: currentPage,
    setPage,
  } = useList({
    data: data ?? [],
    filter: {
      q: filters.searchQuery,
      sectionIndex: filters.sectionSearch,
      sectionGroupIndex: filters.activitySearch,
    },
    perPage: ITEMS_PER_PAGE,
  });

  if (isError) return <>Something went wrong</>;

  const totalPages = useMemo(() => {
    return Math.ceil(totalNumberOfData / ITEMS_PER_PAGE);
  }, [totalNumberOfData]);

  return (
    <Box py={1.5}>
      <FeedFilters filters={filters} onSearchChange={handleSearchChange} />
      <Box mt={2}>
        {isLoading ? <Loading /> : <FeedList data={listData ?? []} />}
      </Box>
      <Pagination
        variant='outlined'
        shape='rounded'
        count={totalPages}
        page={currentPage}
        onChange={(e, value) => setPage(value)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 5,
        }}
      />
    </Box>
  );
};

export default ProgressFeed;
