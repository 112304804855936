import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Loading, ReferenceField, useGetOne, useList } from 'react-admin';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Pagination,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import {
  KeyopsHeader3,
  KeyopsSubTitle1,
  KeyopsSubTitle2,
} from '@keyops-hcp/ui-components';
import { dateFormatter } from '@keyops-hcp/ui-components/dist/utils/datetime';

import FeedFilters from './FeedFilters';
import FeedList from './FeedList';
import useProgressFeed from '../../../../custom-hooks/useProgressFeed';

const ITEMS_PER_PAGE = 10;

const stringAvatar = (name: string) => {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

const getTimeToComplete = (completedOn: Date, startedOn: Date): string => {
  if (!startedOn || !completedOn) return '-';
  // Calculate the difference in milliseconds
  const timeDiff = Math.abs(completedOn.getTime() - startedOn.getTime());

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

  return `${hours}:${minutes}`;
};

const IndividualFeed = ({ userId, participantData, handleParticipantFeed }) => {
  const { data: user } = useGetOne('Users', { id: userId });

  const [filters, setFilters] = useState({
    searchQuery: '',
    sectionSearch: '',
    activitySearch: '',
  });

  const handleSearchChange = useCallback(
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>,
      field: string
    ) => {
      setFilters((prev) => ({ ...prev, [field]: e.target.value }));
    },
    []
  );

  const { isLoading, isError, data } = useProgressFeed();
  const {
    data: listData,
    total: totalNumberOfData,
    page: currentPage,
    setPage,
  } = useList({
    data: data ?? [],
    filter: {
      userId,
      q: filters.searchQuery,
      sectionIndex: filters.sectionSearch,
      sectionGroupIndex: filters.activitySearch,
    },
    perPage: ITEMS_PER_PAGE,
  });

  const currentSection = useMemo(
    () => Math.max(...(data?.map((item) => item.sectionIndex) || [0])),
    []
  );
  const totalPages = useMemo(() => {
    return Math.ceil(totalNumberOfData / ITEMS_PER_PAGE);
  }, [totalNumberOfData]);

  const participantProgressDetails = useMemo(
    () => [
      {
        label: 'Started on',
        value: dateFormatter(participantData.progress.startedOn),
      },
      {
        label: 'Completed on',
        value: dateFormatter(participantData.progress.finishedOn),
      },
      {
        label: 'Completion rate',
        value: `${participantData.completionPercentage ?? '0'}%`,
      },
      {
        label: 'Average time to complete',
        value: getTimeToComplete(
          participantData.progress?.finishedOn,
          participantData.progress?.startedOn
        ),
      },
    ],
    [participantData]
  );

  if (isError) return <>Something went wrong</>;

  return (
    <Box width={'100%'}>
      <Button
        startIcon={<ArrowBackIosNewRounded sx={{ fontSize: 20 }} />}
        onClick={() => handleParticipantFeed(null)}
      >
        Back
      </Button>
      <Container maxWidth='md' sx={{ mt: 3 }}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Avatar
            {...stringAvatar(`${user.firstName} ${user.lastName}`)}
            sx={{
              width: 56,
              height: 56,
              backgroundColor: '#3376D1',
              color: '#FFFFFF',
            }}
          />
          <Box>
            <KeyopsSubTitle1 color={'black'}>
              {user.firstName} {user.lastName}
              <ReferenceField
                source='user_id'
                reference='users'
                label='Author'
              />
            </KeyopsSubTitle1>
            <KeyopsSubTitle2>Title</KeyopsSubTitle2>
          </Box>
        </Stack>
        <Box mt={3}>
          <FeedFilters filters={filters} onSearchChange={handleSearchChange} />
        </Box>
        <Card variant='outlined' sx={{ mt: 3, borderRadius: 2 }}>
          <CardContent>
            <Box display={'flex'} alignItems={'center'} width={'100%'}>
              <Stack gap={1}>
                <KeyopsSubTitle2>Currently on</KeyopsSubTitle2>
                <KeyopsHeader3>Section {currentSection}</KeyopsHeader3>
              </Stack>
              <Divider orientation='vertical' sx={{ mx: 3 }} flexItem />
              <Box
                display={'flex'}
                flexGrow={1}
                justifyContent={'space-between'}
              >
                {participantProgressDetails.map(({ label, value }, idx) => (
                  <Stack key={idx} gap={1}>
                    <KeyopsSubTitle2>{label}</KeyopsSubTitle2>
                    <KeyopsHeader3>
                      {isLoading ? <Loading /> : value ?? '-'}
                    </KeyopsHeader3>
                  </Stack>
                ))}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box mt={2}>
          {isLoading ? <Loading /> : <FeedList data={listData ?? []} />}
        </Box>
        <Pagination
          variant='outlined'
          shape='rounded'
          count={totalPages}
          page={currentPage}
          onChange={(e, value) => setPage(value)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
          }}
        />
      </Container>
    </Box>
  );
};

export default IndividualFeed;
