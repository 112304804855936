import React, { useMemo, useState } from 'react';
import { Loading, useEditContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { AdBoardDto } from '@keyops-hcp/dtos';

import SummarySection from './SummarySection';
import EngagementDetailsSection from './EngagementDetailsSection';
import IndividualFeed from './IndividualFeed';
import EmptyEngagement from './EmptyEngagement';
import useAdBoardEngagementData from './useAdBoardEngagementData';

const EngagementTabContent = () => {
  const navigate = useNavigate();

  const [showParticipantFeed, setShowParticipantFeed] = useState<number | null>(
    null
  );
  const handleParticipantFeed = (value: number | null) =>
    setShowParticipantFeed(value);

  const {
    record: adBoardData,
    isLoading,
    isFetching,
  } = useEditContext<AdBoardDto>();

  const engagementData = useAdBoardEngagementData();
  if (engagementData.isError) navigate('/AdBoards');

  const individualParticipantData = useMemo(() => {
    if (showParticipantFeed && engagementData) {
      return engagementData.participantsData?.find(
        (participant) => participant.userId === showParticipantFeed
      );
    }
    return null;
  }, [showParticipantFeed, engagementData]);

  if (isFetching || isLoading || !adBoardData) {
    return <Loading />;
  }

  // Main content when record is in 'live' or 'closed' state
  if (adBoardData.state !== 'live' && adBoardData.state !== 'closed') {
    return <EmptyEngagement />;
  }

  return (
    <>
      {showParticipantFeed ? (
        <IndividualFeed
          userId={showParticipantFeed}
          participantData={individualParticipantData}
          handleParticipantFeed={handleParticipantFeed}
        />
      ) : (
        <>
          <SummarySection engagementData={engagementData} />
          <EngagementDetailsSection
            handleParticipantFeed={handleParticipantFeed}
          />
        </>
      )}
    </>
  );
};

export default EngagementTabContent;
