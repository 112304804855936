import React, { useMemo } from 'react';
import { Loading } from 'react-admin';
import { Box, Container, Stack, useTheme } from '@mui/material';
import { KeyopsHeader2, KeyopsSubTitle2 } from '@keyops-hcp/ui-components';

import { AdBoardEngagementDataDto } from './useAdBoardEngagementData';

const SummarySection = ({
  engagementData,
}: {
  engagementData: AdBoardEngagementDataDto;
}) => {
  const { palette } = useTheme();

  // Memoize the stats data
  const stats = useMemo(
    () => [
      { label: 'Participants', value: engagementData.totalParticipants },
      { label: 'Active today', value: engagementData.activeToday },
      { label: 'In progress', value: engagementData.inProgressParticipants },
      { label: 'Completed', value: engagementData.completedParticipants },
      {
        label: 'Completion rate',
        value: `${engagementData.completionPercentage ?? '0'}%`,
      },
    ],
    [engagementData]
  );

  return (
    <Container maxWidth='sm' sx={{ mt: 2 }}>
      <Box
        sx={{
          border: `1px solid ${palette.keyops.gray[200]}`,
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2.5,
          py: 1.5,
          gap: 2,
        }}
      >
        {stats.map(({ label, value }, idx) => (
          <Stack key={idx} gap={1}>
            <KeyopsSubTitle2>{label}</KeyopsSubTitle2>
            <KeyopsHeader2>
              {engagementData.isLoading ? <Loading /> : value ?? '-'}
            </KeyopsHeader2>
          </Stack>
        ))}
      </Box>
    </Container>
  );
};

export default SummarySection;
